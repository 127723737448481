<template>
  <b-row>
    <b-col>
      <div v-if="pageLoading"><b-spinner /></div>
      <b-form @submit.stop.prevent="onSubmit" class="mb-5" v-else>
        <Alert v-if="errorMessage" variant="red" dismissible fade> Failed to edit fees: {{ errorMessage }} </Alert>
        <Alert v-if="saved" variant="green" dismissible fade> Successfully saved fees! </Alert>

        <b-row>
          <b-col xl="5" lg="5" md="12" sm="12">
            <b-form-group label="Rafflebox Fees" label-class="font-weight-bold text-2xl pt-0 mb-4">
              <b-form-group label="Online" label-size="lg" label-class="font-weight-bold">
                <b-form-row>
                  <b-form-group
                    label="Fee Percent"
                    label-for="input-rafflebox-fee-percent"
                    :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent')"
                    :state="validateState('input-rafflebox-fee-percent')"
                    class="mb-4 col-5 col-md-6 col-lg-6"
                  >
                    <b-input-group append="%" class="mr-3">
                      <b-form-input
                        name="input-rafflebox-fee-percent"
                        v-model="raffleboxFeePercent"
                        type="number"
                        step="any"
                        :state="validateState('input-rafflebox-fee-percent')"
                        v-validate="{ min_value: 0, max_value: 100, required: true }"
                        aria-describedby="input-rafflebox-fee-percent-feedback"
                        data-vv-as="Rafflebox Fee"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    label="Fee Per Order"
                    label-for="input-rafflebox-order-fee-dollars"
                    :invalid-feedback="veeErrors.first('input-rafflebox-order-fee-dollars')"
                    :state="validateState('input-rafflebox-order-fee-dollars')"
                    class="mb-4 col-5 col-md-6 col-lg-6 whitespace-nowrap"
                  >
                    <b-input-group prepend="$" class="mr-3">
                      <b-form-input
                        name="input-rafflebox-order-fee-dollars"
                        v-model="raffleboxOrderFeeDollars"
                        type="number"
                        step="any"
                        :state="validateState('input-rafflebox-order-fee-dollars')"
                        v-validate="{ min_value: 0, required: true }"
                        aria-describedby="input-rafflebox-order-fee-dollars-feedback"
                        data-vv-as="Rafflebox fee per order"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-form-group>
              <b-form-group label="Goldrush" label-size="lg" label-class="font-weight-bold">
                <b-form-row>
                  <b-form-group
                    label="Fee Percent"
                    label-for="input-rafflebox-fee-percent-goldrush"
                    :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent-goldrush')"
                    :state="validateState('input-rafflebox-fee-percent-goldrush')"
                    class="mb-4 col-5 col-md-6 col-lg-6"
                  >
                    <b-input-group append="%" class="mr-3">
                      <b-form-input
                        name="input-rafflebox-fee-percent-goldrush"
                        v-model="raffleboxFeePercentGoldrush"
                        type="number"
                        step="any"
                        :state="validateState('input-rafflebox-fee-percent-goldrush')"
                        v-validate="{ min_value: 0, max_value: 100 }"
                        aria-describedby="input-rafflebox-fee-percent-goldrush-feedback"
                        data-vv-as="Goldrush fee percent"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-form-group>
              <b-form-group label="In-Venue" label-size="lg" label-class="font-weight-bold">
                <b-form-row>
                  <b-form-group
                    label="Fee Percent"
                    label-for="input-rafflebox-fee-percent-in-venue"
                    :invalid-feedback="veeErrors.first('input-rafflebox-fee-percent-in-venue')"
                    :state="validateState('input-rafflebox-fee-percent-in-venue')"
                    class="mb-4 col-5 col-md-6 col-lg-6"
                  >
                    <b-input-group append="%" class="mr-3">
                      <b-form-input
                        name="input-rafflebox-fee-percent-in-venue"
                        v-model="raffleboxFeePercentIv"
                        type="number"
                        step="any"
                        :state="validateState('input-rafflebox-fee-percent-in-venue')"
                        v-validate="{ min_value: 0, max_value: 100 }"
                        aria-describedby="input-rafflebox-fee-percent-in-venue-feedback"
                        data-vv-as="In-Venue fee percent"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col xl="5" lg="5" md="12" sm="12">
            <b-form-group label="Merchant Fees" label-class="font-weight-bold text-2xl pt-0 mb-4">
              <b-form-group label="Stripe (Online)" label-size="lg" label-class="font-weight-bold">
                <b-form-row>
                  <b-form-group
                    label-for="input-stripe-connect-tx-fee-percent"
                    :invalid-feedback="veeErrors.first('input-stripe-connect-tx-fee-percent')"
                    :state="validateState('input-stripe-connect-tx-fee-percent')"
                    class="col-5 col-md-6 col-lg-6"
                  >
                    <template slot="label"
                      >Stripe Connect Fee
                      <sup
                        v-b-tooltip.hover.top="
                          'Credit card rates may vary depending on the card type. For example, Amex is higher.'
                        "
                      >
                        <span class="fas fa-info-circle"></span>
                      </sup>
                    </template>
                    <b-input-group append="%" class="mr-3">
                      <b-form-input
                        name="input-stripe-connect-tx-fee-percent"
                        v-model="stripeConnectTxFeePercent"
                        type="number"
                        step="any"
                        :state="validateState('input-stripe-connect-tx-fee-percent')"
                        v-validate="{ min_value: 2, max_value: 2.9 }"
                        aria-describedby="input-stripe-connect-tx-fee-percent-feedback"
                        data-vv-as="stripe connect tx percent fee"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    label="Stripe Connect Tx Fee"
                    label-for="input-stripe-connect-tx-fee-dollars"
                    :invalid-feedback="veeErrors.first('input-stripe-connect-tx-fee-dollars')"
                    :state="validateState('input-stripe-connect-tx-fee-dollars')"
                    class="col-5 col-md-6 col-lg-6 whitespace-nowrap"
                  >
                    <b-input-group prepend="$" class="mr-3">
                      <b-form-input
                        name="input-stripe-connect-tx-fee-dollars"
                        v-model="stripeConnectTxFeeDollars"
                        type="number"
                        step="any"
                        :state="validateState('input-stripe-connect-tx-fee-dollars')"
                        v-validate="{ min_value: 0.2, max_value: 0.35 }"
                        aria-describedby="input-stripe-connect-tx-fee-dollars-feedback"
                        data-vv-as="stripe connect tx fee dollars"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-form-group>
              <b-form-group label="PayFacto (In-Venue)" label-size="lg" label-class="font-weight-bold">
                <b-form-row>
                  <b-form-group
                    label="Payfacto Fee"
                    label-for="input-payfacto-tx-fee-percent"
                    :invalid-feedback="veeErrors.first('input-payfacto-tx-fee-percent')"
                    :state="validateState('input-payfacto-tx-fee-percent')"
                    class="col-5 col-md-6 col-lg-6"
                  >
                    <b-input-group append="%" class="mr-3">
                      <b-form-input
                        name="input-payfacto-tx-fee-percent"
                        v-model="payfactoTxFeePercent"
                        type="number"
                        step="any"
                        :state="validateState('input-payfacto-tx-fee-percent')"
                        v-validate="{ min_value: 2, max_value: 2.9 }"
                        aria-describedby="input-payfacto-tx-fee-percent-feedback"
                        data-vv-as="payfacto tx percent fee"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    label="Payfacto Tx Fee"
                    label-for="input-payfacto-tx-fee-dollars"
                    :invalid-feedback="veeErrors.first('input-payfacto-tx-fee-dollars')"
                    :state="validateState('input-payfacto-tx-fee-dollars')"
                    class="col-5 col-md-6 col-lg-6"
                  >
                    <b-input-group prepend="$" class="mr-3">
                      <b-form-input
                        name="input-payfacto-tx-fee-dollars"
                        v-model="payfactoTxFeeDollars"
                        type="number"
                        step="any"
                        :state="validateState('input-payfacto-tx-fee-dollars')"
                        v-validate="{ min_value: 0.15, max_value: 1.0 }"
                        aria-describedby="input-payfacto-tx-fee-dollars-feedback"
                        data-vv-as="payfacto tx fee dollars"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-form-row>
              </b-form-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <LoadingButton variant="green" @click.native="onSubmit" :loading="isSaving">Save</LoadingButton>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import LoadingButton from '@/components/ui/LoadingButton';
import Alert from '@/components/ui/Alert';

export default {
  components: { Alert, LoadingButton },
  data() {
    return {
      organization: null,
      stripeConnectTxFeeDollars: null,
      stripeConnectTxFeePercent: null,
      payfactoTxFeeDollars: null,
      payfactoTxFeePercent: null,
      raffleboxFeePercent: null,
      raffleboxFeePercentGoldrush: null,
      raffleboxFeePercentIv: null,
      raffleboxOrderFeeDollars: null,
      editMerchantFailed: false,
      errorMessage: null,
      id: null,
      stripeConnectedAccountId: null,
      isSaving: false,
      saved: false,
      pageLoading: false
    };
  },
  async created() {
    try {
      this.pageLoading = true;
      const sessionUser = await getAuth().sessionUser();
      this.organizationId = this.$route.query.id || sessionUser.organizationUuid;

      const organization = await OrganizationServiceV2.retrieveOrganization(this.organizationId);

      this.organization = organization;
      this.resetForm();
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
    } finally {
      this.pageLoading = false;
    }
  },
  watch: {
    raffleboxOrderFeeDollars: async function (value) {
      if (value > 0) {
        this.raffleboxFeePercent = 0;
      }
    },
    raffleboxFeePercent: function (value) {
      if (value > 0) {
        this.raffleboxOrderFeeDollars = 0;
      }
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.errorMessage = null;

      this.id = this.organization.id;
      this.saved = false;

      this.stripeConnectedAccountId = this.organization.stripeConnectedAccountId;

      this.raffleboxFeePercent = this.organization.raffleboxFeePercent;
      this.raffleboxFeePercentGoldrush = this.organization.raffleboxFeePercentGoldrush;
      this.raffleboxFeePercentIv = this.organization.raffleboxFeePercentIv;
      this.stripeConnectTxFeeDollars = this.organization.merchantTxFeeCents / 100.0 || null;
      this.stripeConnectTxFeePercent = this.organization.merchantTxFeePercent || null;
      this.payfactoTxFeeDollars = this.organization.ivMerchantTxFeeCents / 100.0 || null;
      this.payfactoTxFeePercent = this.organization.ivMerchantTxFeePercent || null;
      this.raffleboxOrderFeeDollars = this.organization.raffleboxOrderFeeCents / 100.0 || 0;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        this.isSaving = true;

        const data = {
          stripeConnectedAccountId: this.stripeConnectedAccountId,
          defaultMerchant: this.defaultMerchant,
          raffleboxFeePercent: Number(this.raffleboxFeePercent),
          raffleboxOrderFeeCents: Math.round(this.raffleboxOrderFeeDollars * 100),
          raffleboxFeePercentGoldrush: Number(this.raffleboxFeePercentGoldrush),
          raffleboxFeePercentIv: Number(this.raffleboxFeePercentIv)
        };

        if (this.stripeConnectTxFeeDollars) {
          data.merchantTxFeeCents = Math.round(this.stripeConnectTxFeeDollars * 100);
        }

        if (this.stripeConnectTxFeePercent) {
          data.merchantTxFeePercent = this.stripeConnectTxFeePercent;
        }

        if (this.payfactoTxFeeDollars) {
          data.ivMerchantTxFeeCents = Math.round(this.payfactoTxFeeDollars * 100);
        }

        if (this.payfactoTxFeePercent) {
          data.ivMerchantTxFeePercent = this.payfactoTxFeePercent;
        }

        await OrganizationServiceV2.updateOrganization(this.organization.id, data);
        this.saved = true;
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
        this.saved = false;
        this.isSaving = false;
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>
